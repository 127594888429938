import React from "react";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  return (
    <LessonLayout
      current_step={15}
      lesson={"Lesson 4"}
      color={"hs"}
      total_count={15}
      module_title="What Could Happen?"
    >
      <ContentWrapper>
        <H2 underline>Step 15</H2>

        <p>Be ready to share your additions to your rubric in a class discussion.</p>
        <p>If time permits, your teacher may ask you to think about the following information.</p>
        <p>It is now common to see ads for prescription medications on TV, in magazines, or on the internet. Before the early 1980s, companies could not advertise these medications directly to the public. The United States and New Zealand are currently the only countries in the world that allow this type of advertising.</p>

        <UnorderedList>
          <li>Should companies be allowed to advertise prescription drugs directly to consumers?</li>
          <li>How might these ads benefit consumers?</li>
          <li>What are some possible problems related to these ads?</li>
        </UnorderedList>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

